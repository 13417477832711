<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">
        Contratos de servicios
        <div class="bx-pull-right">
          <button
            class="btn btn-link btn-sm"
            @click="nuevoContrato()"
          >
            <i class="mdi mdi-plus"></i>
            Nuevo
          </button>
        </div>
      </h4>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <div class="form-check form-switch form-switch-lg mb-3">
            <input
              v-model="contratosActivos"
              @change="refrescarContratos()"
              class="form-check-input"
              type="checkbox"
              id="palancaActivos"
            />
            <label class="form-check-label" for="palancaActivos">Activos</label>
          </div>
        </div>
        <div>
          <div class="form-check font-size-15" style="display: inline-block;">
            <input
              class="form-check-input"
              type="checkbox"
              id="ping"
              v-model="columnas.ping"
              @change="actualizarValoresColumnasEnLocalStorage()"
            />
            <label class="form-check-label" for="ping">Ping</label>
          </div>
          &nbsp;
          <div class="form-check font-size-15" style="display: inline-block;">
            <input
              class="form-check-input"
              type="checkbox"
              id="periodo"
              v-model="columnas.periodo"
              @change="actualizarValoresColumnasEnLocalStorage()"
            />
            <label class="form-check-label" for="periodo">Periodo del servicio</label>
          </div>
          &nbsp;
          <div class="form-check font-size-15" style="display: inline-block;">
            <input
              class="form-check-input"
              type="checkbox"
              id="fechaPago"
              v-model="columnas.fecha_pago"
              @change="actualizarValoresColumnasEnLocalStorage()"
            />
            <label class="form-check-label" for="fechaPago">Fecha pago</label>
          </div>
          &nbsp;
          <div class="form-check font-size-15" style="display: inline-block;">
            <input
              class="form-check-input"
              type="checkbox"
              id="fechaCorte"
              v-model="columnas.fecha_corte"
              @change="actualizarValoresColumnasEnLocalStorage()"
            />
            <label class="form-check-label" for="fechaCorte">Fecha corte</label>
          </div>
          &nbsp;
          <div class="form-check font-size-15" style="display: inline-block;">
            <input
              class="form-check-input"
              type="checkbox"
              id="facturaCrear"
              v-model="columnas.factura_a_crear"
              @change="actualizarValoresColumnasEnLocalStorage()"
            />
            <label class="form-check-label" for="facturaCrear">Factura a crear</label>
          </div>
        </div>          
      </div>

      <div class="table-responsive" style="min-height: 210px;">
        <table class="table table-hover font-size-13 align-middle table-nowrap">
          <thead>
            <tr>
              <th>#ID</th>
              <th>Servicio</th>
              <th>CPE</th>
              <th v-if="contratosActivos">Dirección IP</th>
              <th v-if="contratosActivos">Estado</th>
              <th v-if="contratosActivos && columnas.ping">Ping</th>
              <th v-if="columnas.periodo">Periodo del servicio</th>
              <th v-if="columnas.fecha_pago">Fecha de pago</th>
              <th v-if="columnas.fecha_corte">Fecha de corte</th>
              <th v-if="columnas.factura_a_crear">Factura a crear</th>
              <th class="text-center" style="width:50px">Mapa</th>
              <th style="width: 50px" class="text-center">Acción</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(contrato, index) in contratos">
              <tr :key="contrato.id" v-if="true">
                <td>
                  <router-link
                    class="btn btn-sm"
                    :to="{
                      name: 'edicionContrato',
                      params: { id_contrato: contrato.id }
                    }"
                    :class="{
                      'btn-light': contrato.cancelado == false,
                      'btn-outline-danger': contrato.cancelado == true
                    }"
                  >
                    {{ contrato.id }}
                  </router-link>
                </td>
                <td>
                  <div v-if="contrato.servicio != null">
                    {{ contrato.servicio.nombre }}
                    <sup
                      class="badge badge-pill badge-soft-info"
                      v-show="contrato.id_tipo_renta == 1"
                      title="Renta Mensual Fija"
                    >
                      RMF
                    </sup>
                    <sup
                      class="badge badge-pill badge-soft-warning"
                      v-show="contrato.id_tipo_renta == 2"
                      title="Renta Mensual Variable"
                    >
                      RMV
                    </sup>
                  </div>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'edicionCpe',
                      params: { id: contrato.cpe.id }
                    }"
                    v-if="contrato.cpe != null"
                  >
                    {{ contrato.cpe.nombre }}
                  </router-link>
                </td>
                <td v-if="contratosActivos == true">
                  <span v-if="contrato.cpe != null">
                    {{ contrato.cpe.ip_wan }}
                  </span>
                </td>
                <td v-if="contratosActivos == true">
                  <span
                    v-show="contrato.cpe != null && contrato.cpe.enlace_activo"
                    class="badge bg-success bg-gradient"
                  >
                    ACTIVO
                  </span>
                  <span
                    v-show="contrato.cpe != null && contrato.cpe.enlace_activo == false"
                    class="badge bg-danger bg-gradient"
                  >
                    SUSPENDIDO
                  </span>
                </td>
                <td v-if="contratosActivos == true && columnas.ping">
                  <button
                    v-if="contrato.cpe != null"
                    class="btn btn-light btn-xs"
                    @click="mostrarMdlPing(contrato.cpe.id)"
                  >
                    <i class="mdi mdi-radar"></i>
                    Ping
                  </button>
                </td>
                <td v-if="columnas.periodo">
                  {{ formatoFecha(contrato.inicio_periodo) }} al <br />
                  {{ formatoFecha(contrato.fin_periodo) }}
                </td>
                <td v-if="columnas.fecha_pago">
                  {{ formatoFecha(fechaPago(contrato.fin_periodo, contrato.dia_pago)) }}
                </td>
                <td v-if="columnas.fecha_corte">
                  <span v-if="contrato.prorroga <= 0">
                    {{ formatoFecha(fechaCorteServicio(contrato.fin_periodo, contrato.dia_pago, contrato.n_dias_corte)) }}
                  </span>
                  <span v-if="contrato.prorroga > 0">
                    {{ formatoFecha(fechaCorteServicioConProrroga(contrato.fin_periodo, contrato.dia_pago, contrato.n_dias_corte, contrato.prorroga)) }}
                    <sup class="badge bg-info" title="Promesa de pago">P</sup>
                  </span>
                </td>
                <td v-if="columnas.factura_a_crear">
                  {{ formatoFecha(fechaGenerarNuevaFactura(contrato.fin_periodo, contrato.dia_pago, contrato.id_tipo_renta, contrato.n_dias_generar_factura)) }}
                </td>
                <td>
                  <div class="form-check form-switch form-switch-lg ms-4 d-inline-block">
                    <input
                      v-model="contrato.mostrar_mapa"
                      class="form-check-input"
                      type="checkbox"
                      id="mapa"
                    />
                    <label class="form-check-label" for="mapa"></label>
                  </div>
                </td>
                <td class="text-center">
                  <div class="dropdown">
                    <button
                      class="btn btn-light btn-sm dropdown-toggle"
                      type="button" data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bx bx-dots-horizontal-rounded"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li>
                        <router-link :to="{ name: 'edicionContrato', params: { id_contrato: contrato.id } }"
                          class="dropdown-item info"
                        >
                          <i class="mdi mdi-eye text-info"></i>
                          Ver contrato
                          <span v-if="contrato.cancelado == 1">
                            cancelado
                          </span>
                        </router-link>
                      </li>
                      <li>
                        <button
                          class="dropdown-item danger"
                          @click="preguntaCancelarContrato(contrato)"
                          v-if="contrato.cancelado == 0"
                        >
                          <i class="mdi mdi-close text-danger"></i>
                          Cancelar
                        </button>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>

              <tr :key="contrato.id" v-if="contrato.mostrar_mapa">
                <td colspan="11">
                  <GoogleMap
                    ref="mapa"
                    :api-key="apikeyGoogle"
                    :center="{ 
                      lat: contrato.center_latitud, 
                      lng: contrato.center_longitud
                    }"
                    style="height: 250px;"
                    :zoom="contrato.zoom"
                    :styles="modoTemplate == 'dark' ? styles_dark : styles_light"
                    v-if="contrato.mostrar_mapa"
                  >
                    <Marker 
                      :options="{ 
                        position: { 
                          lat: contrato.latitud, 
                          lng: contrato.longitud
                        },
                        icon: markerIcon, 
                        title: contrato.cpe != null ? contrato.cpe.nombre : ''
                      }"
                      @dblclick="zoomChanged(contrato, index)"
                    >
                      <InfoWindow>
                        <div id="contet">
                          <div id="siteNotice"></div>
                          <h5 id="firstHeading" class="firstHeading">
                            {{contrato.cpe != null ? contrato.cpe.nombre : 'Servicio ' + contrato.servicio.id}}
                          </h5>
                          <div id="bodyContent">
                            <p>
                              <strong>Servicio: </strong>{{ contrato.servicio.nombre }}
                              <sup
                                class="badge badge-pill badge-soft-info"
                                v-show="contrato.id_tipo_renta == 1"
                                title="Renta Mensual Fija"
                              >
                                RMF
                              </sup>
                              <sup
                                class="badge badge-pill badge-soft-warning"
                                v-show="contrato.id_tipo_renta == 2"
                                title="Renta Mensual Variable"
                              >
                                RMV
                              </sup>

                              <br v-if="contrato.cpe != null">

                              <strong v-if="contrato.cpe != null">Direción IP: </strong>
                              <span v-if="contrato.cpe != null">
                                {{ contrato.cpe.ip_wan }}
                              </span>

                              <br v-if="contrato.cpe != null">
                              <strong v-show="contrato.cpe != null">Estado: </strong>
                              <span
                                v-show="contrato.cpe != null && contrato.cpe.enlace_activo"
                                class="badge bg-success bg-gradient"
                              >
                                ACTIVO
                              </span>
                              <span
                                v-show="
                                  contrato.cpe != null &&
                                  contrato.cpe.enlace_activo == false
                                "
                                class="badge bg-danger bg-gradient"
                              >
                                SUSPENDIDO
                              </span>
                              <br>
                              <br>
                              <a :href="'https://maps.google.com/?q='+contrato.latitud+','+contrato.longitud+'&entry=gps'" target="_blank">
                                Ver en google maps
                              </a>
                            </p>
                          </div>
                        </div>
                      </InfoWindow>
                    </Marker>
                  </GoogleMap>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <mdl-ping ref="mdlPing" />
  </div>
</template>

<script>
import { GoogleMap, Marker, InfoWindow } from "vue3-google-map"

import markerIcon  from '@/assets/img/location24x36.png'
import ClienteSrv  from '@/services/ClienteSrv.js'
import ContratoSrv from '@/services/ContratoSrv.js'

import MdlPing from "../network/cpes/MdlPing.vue"

import Swal from 'sweetalert2'
import moment from "moment"

export default {
  name: 'CmpContratos',
  components: { 
    GoogleMap,
    Marker,
    InfoWindow,
    MdlPing
  },
  props: {
    idCliente: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      contratos: [],
      cpes: [],

      mapa: null,
      contratosActivos: true,
      markerIcon:markerIcon,
      styles_dark: [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { invert_lightness: true },
            { saturation: 10 },
            { lightness: 30 },
            { gamma: 0.5 },
            { hue: "#435158" }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 }
          ]
        }
      ],
      styles_light:[
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 20 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 40 }
          ]
        },{
          featureType: "water",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -10 },
            { lightness: 30 }
          ]
        },{
          featureType: "landscape.man_made",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 10 }
          ]
        },{
          featureType: "landscape.natural",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 60 }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        }
      ],

      columnas: {
        ping: false,
        periodo: true,
        fecha_pago: true,
        fecha_corte: true,
        factura_a_crear: false,
      },
    }
  },
  computed: {
    modoTemplate: function() {
      return this.$store.state.layout.layoutMode
    },
    apikeyGoogle() {
      return this.$store.state.todo.apiKeyGoogleMaps
    }
  },

  created: function() {
    var self = this

    self.refrescarContratos() // Carga inicial de los contratos asignados al cliente

    // Carga de las columnas visibles
    self.cargarValoresColumnasDeLocalStorage()
  },

  methods: {
    actualizarValoresColumnasEnLocalStorage() {
      let self = this
      localStorage.setItem('argusblack.listadoClientes.tablero.cmpContratos.columnas', JSON.stringify(self.columnas))
    },

    cancelarContrato: function(contrato) {
      var self = this

      ContratoSrv.cancelar(contrato.id).then(response => {
        self.refrescarContratos()
        iu.msg.success('Se canceló el contrato correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cancelar el contrato'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarValoresColumnasDeLocalStorage() {
      let self = this
      let columnas = localStorage.getItem('argusblack.listadoClientes.tablero.cmpContratos.columnas')
      
      if(columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },

    fechaPago(fin_periodo, dia_pago) {
      return moment(fin_periodo, 'YYYY-MM-DD').add(dia_pago, 'days').format('YYYY-MM-DD')
    },

    fechaCorteServicio(fin_periodo, dia_pago, n_dias_corte) {
      return moment(this.fechaPago(fin_periodo, dia_pago), 'YYYY-MM-DD').add(n_dias_corte, 'days').format('YYYY-MM-DD')
    },

    fechaCorteServicioConProrroga(fin_periodo, dia_pago, n_dias_corte, prorroga) {
      let diasASumar = n_dias_corte + prorroga
      return moment(this.fechaPago(fin_periodo, dia_pago), 'YYYY-MM-DD').add(diasASumar , 'days').format('YYYY-MM-DD')
    },

    fechaGenerarNuevaFactura(fin_periodo, dia_pago, id_tipo_renta = 1, n_dias_generar_factura) {
      if(id_tipo_renta == 1)
        return moment(this.fechaPago(fin_periodo, dia_pago), 'YYYY-MM-DD').subtract(n_dias_generar_factura, 'days').format('YYYY-MM-DD')
      else
        return moment(fin_periodo, 'YYYY-MM-DD').subtract(n_dias_generar_factura, 'days').format('YYYY-MM-DD')
    },

    formatoFecha: function (mt) {
      return moment(mt, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    mostrarMdlPing: function (idCpe) {
      var self = this;
      self.$refs.mdlPing.mostrar(idCpe);
    },

    nuevoContrato: function() {
      this.$router.push({
        name: 'nuevoContrato'
      })
    },

    preguntaCancelarContrato: function(contrato) {
      var self = this
      
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Cancelación de contrato",
        html: `¿Está seguro de cancelar el contrato ${contrato.id}?`,
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          ContratoSrv.cancelar(contrato.id).then(response => {
            swal.fire("Cancelado!", "Se canceló el contrato correctamente", "success")
            self.refrescarContratos()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo cancelar el contrato'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error")
        }
      })
    },

    refrescarContratos: function() {
      var self = this

      let params = {
        con_servicio: true,
        con_cpe: true,
        activos: self.contratosActivos == true
      }

      ClienteSrv.contratosJSON(self.idCliente, params).then(response => {
        let contratos = response.data

        contratos.forEach(contrato => {
          contrato.mostrar_mapa = false
          contrato.center_latitud = contrato.latitud
          contrato.center_longitud = contrato.longitud
          contrato.zoom = 12
        });

        self.contratos = contratos
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    zoomChanged: function(contrato, index) {      
      // Obtener el mapa desde la referencia
      const map = this.$refs.mapa[index].map;  // Acceder al mapa directamente

      if (map) {
        // Establecer el nuevo zoom y el centro
        const newZoom = contrato.zoom + 1;  // Aumentamos el zoom en 1
        const newLat = contrato.latitud;    // Latitud del marcador
        const newLng = contrato.longitud;   // Longitud del marcador

        // Actualizamos el estado del contrato con los nuevos valores
        contrato.zoom = newZoom;
        contrato.center_latitud = newLat;
        contrato.center_longitud = newLng;

        // Cambiar el zoom en el mapa
        map.setZoom(newZoom);

        // Cambiar el centro en el mapa de manera explícita
        map.panTo({ lat: newLat, lng: newLng });  // Usamos panTo para centrar el mapa
      }
    }
  }
}
</script>
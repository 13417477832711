<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <div class="flex-shrink-0 me-3">
          <div class="avatar align-items-center">
            <div class="avatar-title rounded bg-primary bg-gradient">
              <eva-icon name="credit-card-outline" class="fill-white font-size-20"></eva-icon>
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <h5 class="text-muted mb-1">Cuenta</h5>
          <h4 class="mb-0">
            <router-link
              :to="{
                name: 'facturasPendientesPago',
                params: { id_cliente: idCliente }
              }"
              class="text-dark"
            >
              {{ formatoMoneda(saldo) }}
            </router-link>
          </h4>
        </div>

        <div class="flex-shrink-0 align-self-end ms-2">
          <span class="badge rounded-pill font-size-12 badge-soft-success">
            Saldo por cobrar
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClienteSrv from '@/services/ClienteSrv.js'
import accounting from 'accounting/accounting.js'

export default {
  name: 'CmpCuenta',
  props: {
    idCliente: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      baseUrl: window.location.origin,
      saldo: 0
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },

  created: function() {
    var self = this
    this.refrescarSaldo()

    iu.bus.on('abonos-actualizados', function() {
      self.refrescarSaldo()
    })
  },
  methods: {
    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },

    refrescarSaldo: function() {
      var cmp = this

      ClienteSrv.saldo(this.idCliente).then(response => {
        let saldo = response.data
        cmp.saldo = saldo >= 0 ? saldo : 0
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo refrescar el saldo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
.text-dark:hover {
  color: #3B76E1 !important;
}
</style>
